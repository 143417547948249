
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        























































.blog-hero,
[class*='blog-hero--'] {
  position: relative;
  margin-top: $spacing * 4;
}

.blog-hero__headline {
  @extend %text-uppercase;

  display: flex;
  align-items: center;
}

.blog-hero__headline__category {
  @extend %box-shadow;
  @extend %text-uppercase;

  padding: $spacing / 2 $spacing;
  color: $c-anthracite-light;
  font-size: 1.4rem;
  font-weight: 700;
  background-color: $white;
  border-radius: 1rem;
}

.blog-hero__headline__date {
  position: relative;

  .blog-hero__headline__category + & {
    margin-left: $spacing / 2;
    padding-left: $spacing / 2;
  }
}

.blog-hero__title {
  margin-top: $spacing * 2;

  @include mq(m) {
    width: inner-col(16, 14);
  }
}

.blog-hero__visuals {
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  height: 18rem;

  @include mq(m) {
    right: 10rem;
    width: inner-col(16, 3);
    max-width: 60rem;
    height: 100%;
    padding: 0;
  }
}
