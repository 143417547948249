
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


















































































































































.blog-share__label {
  @extend %text-uppercase;

  color: $c-gray;
}

.blog-share__items {
  @extend %list-nostyle;

  display: flex;
  margin-top: $spacing;
  list-style: none;
  cursor: pointer;
}

.blog-share__link {
  display: flex;

  * {
    pointer-events: none;
  }
}

.blog-share__link__icon {
  margin-right: 1rem;
  fill: $c-blue-dark;
}
