
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        













































.blog-author {
  margin-top: $spacing * 4;
  display: block;
  text-decoration: none;
  color: currentcolor;

  @include mq($from: m, $until: l) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.blog-author__picture__container {
  position: relative;
  overflow: hidden;
  user-select: none;
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 100%;
  margin-bottom: $spacing * 0.75;
}

.blog-author__picture {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-author__profile__name {
  font-weight: 500;
}

.blog-author__profile__short-description {
  font-size: 0.9em;
  font-weight: 100;
}
